
















































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import echarts from "echarts";
import {
  GetReportData,
  PostFenzhongxinBingli,
  PostYanjiuxiaozuBingli,
  PostYanjiuzheBingli,
  PostFenzhongxinShujizhiliang,
  PostyanjiuzuShujizhiliang,
  PostyanjiuzheShujizhiliang,
  PostfenzhongxinShihuo,
  PostshenhezheShihuo,
} from "@/request/report";
@Component({
  components: {
    MainHeader,
    NavLeft,
  },
})
export default class Name extends Vue {
  private data: any = {
    allData: {},
    病例数量统计: {
      分中心: [],
      研究小组: [],
      研究者: [],
    },
    数据质量总体情况: {
      分中心: [],
      研究小组: [],
      研究者: [],
    },
    失活原因: {
      分中心: {},
      审核账户: {},
    },
  };
  private filter: any = {
    病例数量统计: {
      统计时间: "",
      startTime: "",
      endTime: "",
      分中心: "",
      研究小组: "",
    },
    数据质量总体情况: {
      统计时间: "",
      数据类型: "",
      数据状态: "",
      startTime: "",
      endTime: "",
      分中心: "",
      研究小组: "",
    },
    失活原因: {
      统计时间: "",
      数据类型: "",
      startTime: "",
      endTime: "",
    },
  };
  private timeOptions: any = ["最近1年", "最近半年", "最近1个月"];
  private typeOptions: any = ["诊次", "电话访视"];
  private colors: any = [
    "#0490FD",
    "#DB6153",
    "#637698",
    "#04FD60",
    "#C9C9C9",
    "#5A9BD5",
    "#FF9668",
    "#8FC31F",
    "#F35833",
    "#00CCFF",
    "#FFCC01",
    "#A74FAF",
    "#5D65E7",
    "#E75DB5",
    "#3BE5E7",
    "#E304FD",
    "#ECB976",
    "#FDFB04",
    "#FD2104",
  ];
  private statusOptions: any = [
    "失效",
    "草稿",
    "无效",
    "待确认",
    "待审核",
    "质量审核员已打回",
    "质量审核员通过",
    "分中心负责人通过",
    "监查员已打回",
    "监查员通过",
    "锁定",
    "失活",
  ];
  private get center() {
    const localCenter = localStorage.getItem("center");
    if (!localCenter) {
      return {};
    } else {
      return JSON.parse(localCenter);
    }
  }
  private disabledStartDate: any = this.beginDate();
  private disabledEndDate: any = this.endDate();
  private disabledStartDate2: any = this.beginDate2();
  private disabledEndDate2: any = this.endDate2();
  private disabledStartDate3: any = this.beginDate3();
  private disabledEndDate3: any = this.endDate3();
  private beginDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        if (self.filter["病例数量统计"].endTime) {
          return (
            time.getTime() >
              new Date(self.filter["病例数量统计"].endTime).getTime() ||
            time.getTime() > Date.now()
          );
        } else {
          return time.getTime() > Date.now();
        }
      },
    };
  }
  private endDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        if (self.filter["病例数量统计"].startTime) {
          return (
            new Date(self.filter["病例数量统计"].startTime).getTime() >
              time.getTime() || time.getTime() > Date.now()
          );
        } else {
          return time.getTime() > Date.now();
        }
      },
    };
  }
  private beginDate2() {
    const self = this;
    return {
      disabledDate(time: any) {
        if (self.filter["数据质量总体情况"].endTime) {
          return (
            time.getTime() >
              new Date(self.filter["数据质量总体情况"].endTime).getTime() ||
            time.getTime() > Date.now()
          );
        } else {
          return time.getTime() > Date.now();
        }
      },
    };
  }
  private endDate2() {
    const self = this;
    return {
      disabledDate(time: any) {
        if (self.filter["数据质量总体情况"].startTime) {
          return (
            new Date(self.filter["数据质量总体情况"].startTime).getTime() >
              time.getTime() || time.getTime() > Date.now()
          );
        } else {
          return time.getTime() > Date.now();
        }
      },
    };
  }
  private beginDate3() {
    const self = this;
    return {
      disabledDate(time: any) {
        if (self.filter["失活原因"].endTime) {
          return (
            time.getTime() >
              new Date(self.filter["失活原因"].endTime).getTime() ||
            time.getTime() > Date.now()
          );
        } else {
          return time.getTime() > Date.now();
        }
      },
    };
  }
  private endDate3() {
    const self = this;
    return {
      disabledDate(time: any) {
        if (self.filter["失活原因"].startTime) {
          return (
            new Date(self.filter["失活原因"].startTime).getTime() >
              time.getTime() || time.getTime() > Date.now()
          );
        } else {
          return time.getTime() > Date.now();
        }
      },
    };
  }
  /**
   * @description 选择筛选项
   */
  private clickFilter(key: any, type: any, val: any) {
    this.filter[key][type] = val;
    if (
      type === "统计时间" &&
      val === "自定义" &&
      !this.filter[key].startTime &&
      !this.filter[key].endTime
    ) {
      this.$message.error("开始时间和结束时间至少要选择一个！");
      return;
    }
    if (type === "统计时间" && val !== "自定义") {
      this.filter[key].startTime = "";
      this.filter[key].endTime = "";
    }
    this.filter[key]["分中心"] = "";
    this.filter[key]["研究小组"] = "";
    if (key === "病例数量统计") {
      this.getFenzhongxinBingli();
      this.getXiaozuBingli();
      this.getYanjiuzheBingli();
    } else if (key === "数据质量总体情况") {
      this.getFenzhongxinZhiliang();
      this.getXiaozuZhiliang();
      this.getYanjiuzheZhiliang();
    } else if (key === "失活原因") {
      this.getFenzhongxinShihuo();
      this.getShenhezheShihuo();
    }
  }
  /**
   * @description 点击刷新按钮
   */
  private refresh(key: any, type: any) {
    if (key === "病例数量统计") {
      if (type === "研究小组") {
        this.filter[key]["分中心"] = "";
        this.getXiaozuBingli();
      } else if (type === "研究者") {
        this.filter[key]["研究小组"] = "";
        this.getYanjiuzheBingli();
      }
    } else if (key === "数据质量总体情况") {
      if (type === "研究小组") {
        this.filter[key]["分中心"] = "";
        this.getXiaozuZhiliang();
      } else if (type === "研究者") {
        this.filter[key]["研究小组"] = "";
        this.getYanjiuzheZhiliang();
      }
    } else if (key === "失活原因") {
      this.filter[key]["分中心"] = "";
      this.getShenhezheShihuo();
      this.getFenzhongxinShihuo();
    }
  }
  /**
   * @description 选择分中心或研究小组
   */
  private selectPart(key: any, type: any, id: any) {
    this.filter[key][type] = id;
    if (key === "病例数量统计") {
      if (type === "分中心") {
        this.filter[key]["分中心"] = id;
        this.filter[key]["研究小组"] = "";
        this.getXiaozuBingli();
      } else if (type === "研究小组") {
        this.filter[key]["研究小组"] = id;
        this.getYanjiuzheBingli();
      }
    } else if (key === "数据质量总体情况") {
      if (type === "分中心") {
        this.filter[key]["分中心"] = id;
        this.filter[key]["研究小组"] = "";
        this.getXiaozuZhiliang();
      } else if (type === "研究小组") {
        this.filter[key]["研究小组"] = id;
        this.getYanjiuzheZhiliang();
      }
    }
  }
  /**
   * @description 获取总数据
   */
  private getAllData() {
    const params = {
      params: {
        project_id: this.center.project_id,
      },
    };
    GetReportData(this, params).then((res: any) => {
      this.data.allData = res;
    });
  }
  /**
   * @description 分中心病例数量统计
   */
  private getFenzhongxinBingli() {
    const params: any = {
      project_id: this.center.project_id,
      search_time: this.filter["病例数量统计"]["统计时间"] || "全部时间",
    };
    if (this.filter["病例数量统计"]["统计时间"] === "自定义") {
      params.search_time = "";
      params.start_time = this.filter["病例数量统计"].startTime;
      params.end_time = this.filter["病例数量统计"].endTime;
    }
    PostFenzhongxinBingli(this, params).then((res: any) => {
      this.data["病例数量统计"]["分中心"] = res;
    });
  }
  /**
   * @description 研究小组病例数量统计
   */
  private getXiaozuBingli() {
    const params: any = {
      project_id: this.center.project_id,
      search_time: this.filter["病例数量统计"]["统计时间"] || "全部时间",
      institution_id: this.filter["病例数量统计"]["分中心"],
    };
    if (this.filter["病例数量统计"]["统计时间"] === "自定义") {
      params.search_time = "";
      params.start_time = this.filter["病例数量统计"].startTime;
      params.end_time = this.filter["病例数量统计"].endTime;
    }
    PostYanjiuxiaozuBingli(this, params).then((res) => {
      this.data["病例数量统计"]["研究小组"] = res;
    });
  }
  /**
   * @description 研究者病例数量统计
   */
  private getYanjiuzheBingli() {
    const params: any = {
      project_id: this.center.project_id,
      search_time: this.filter["病例数量统计"]["统计时间"] || "全部时间",
      institution_id: this.filter["病例数量统计"]["研究小组"],
    };
    if (this.filter["病例数量统计"]["统计时间"] === "自定义") {
      params.search_time = "";
      params.start_time = this.filter["病例数量统计"].startTime;
      params.end_time = this.filter["病例数量统计"].endTime;
    }
    PostYanjiuzheBingli(this, params).then((res: any) => {
      this.data["病例数量统计"]["研究者"] = res;
    });
  }
  /**
   * @description 分中心数据质量统计
   */
  private getFenzhongxinZhiliang() {
    const params: any = {
      project_id: this.center.project_id,
      search_time: this.filter["数据质量总体情况"]["统计时间"] || "全部时间",
      type: this.filter["数据质量总体情况"]["数据类型"],
      status: this.filter["数据质量总体情况"]["数据状态"],
    };
    if (this.filter["数据质量总体情况"]["统计时间"] === "自定义") {
      params.search_time = "";
      params.start_time = this.filter["数据质量总体情况"].startTime;
      params.end_time = this.filter["数据质量总体情况"].endTime;
    }
    PostFenzhongxinShujizhiliang(this, params).then((res: any) => {
      this.data["数据质量总体情况"]["分中心"] = res;
    });
  }
  /**
   * @description 研究小组数据质量统计
   */
  private getXiaozuZhiliang() {
    const params: any = {
      project_id: this.center.project_id,
      search_time: this.filter["数据质量总体情况"]["统计时间"] || "全部时间",
      type: this.filter["数据质量总体情况"]["数据类型"],
      status: this.filter["数据质量总体情况"]["数据状态"],
      institution_id: this.filter["数据质量总体情况"]["分中心"],
    };
    if (this.filter["数据质量总体情况"]["统计时间"] === "自定义") {
      params.search_time = "";
      params.start_time = this.filter["数据质量总体情况"].startTime;
      params.end_time = this.filter["数据质量总体情况"].endTime;
    }
    PostyanjiuzuShujizhiliang(this, params).then((res: any) => {
      this.data["数据质量总体情况"]["研究小组"] = res;
    });
  }
  /**
   * @description 研究者数据质量统计
   */
  private getYanjiuzheZhiliang() {
    const params: any = {
      project_id: this.center.project_id,
      search_time: this.filter["数据质量总体情况"]["统计时间"] || "全部时间",
      type: this.filter["数据质量总体情况"]["数据类型"],
      status: this.filter["数据质量总体情况"]["数据状态"],
      institution_id: this.filter["数据质量总体情况"]["研究小组"],
    };
    if (this.filter["数据质量总体情况"]["统计时间"] === "自定义") {
      params.search_time = "";
      params.start_time = this.filter["数据质量总体情况"].startTime;
      params.end_time = this.filter["数据质量总体情况"].endTime;
    }
    PostyanjiuzheShujizhiliang(this, params).then((res: any) => {
      this.data["数据质量总体情况"]["研究者"] = res;
    });
  }
  /**
   * @description 按分中心失活原因
   */
  private getFenzhongxinShihuo() {
    const params: any = {
      project_id: this.center.project_id,
      search_time: this.filter["失活原因"]["统计时间"] || "全部时间",
      type: this.filter["失活原因"]["数据类型"],
    };
    if (this.filter["失活原因"]["统计时间"] === "自定义") {
      params.search_time = "";
      params.start_time = this.filter["失活原因"].startTime;
      params.end_time = this.filter["失活原因"].endTime;
    }
    PostfenzhongxinShihuo(this, params).then((res: any) => {
      this.data["失活原因"]["分中心"] = res;
      const Chart: any = echarts.init(this.$refs.pie as HTMLCanvasElement);
      const option: any = {
        color: [
          "#FF9668",
          "#8FC31F",
          "#F35833",
          "#00CCFF",
          "#FFCC01",
          "#A74FAF",
          "#4F6BED",
          "#5DE7CB",
          "#ED4F6E",
        ],
        legend: {
          orient: "vertical",
          left: "left",
        },
        tooltip: {
          show: true,
          trigger: "item",
          formatter: (pa: any) => {
            return (
              pa.data.name +
              ":" +
              pa.value +
              "&emsp;" +
              (pa.data.percent * 100).toFixed(2) +
              "%"
            );
          },
        },
        series: [
          {
            type: "pie",
            // startAngle: 0,
            selectedMode: "single",
            selectedOffset: 20,
            clockwise: false,
            data: this.data["失活原因"]["分中心"].data,
            label: {
              show: false,
              formatter: (pa: any) => {
                return (
                  pa.data.name +
                  ":" +
                  pa.value +
                  "(" +
                  (pa.data.percent * 100).toFixed(2) +
                  "%" +
                  ")"
                );
              },
            },
            radius: ["40%", "80%"],
            // roseType: 'area'
          },
        ],
      };
      Chart.setOption(option);
      // 点击事件
      Chart.off("click");
      Chart.on("click", (p: any) => {
        this.filter["失活原因"]["分中心"] = p.data.id;
        this.getShenhezheShihuo();
      });
    });
  }
  /**
   * @description 按审核者失活原因
   */
  private getShenhezheShihuo() {
    const params: any = {
      project_id: this.center.project_id,
      search_time: this.filter["失活原因"]["统计时间"],
      type: this.filter["失活原因"]["数据类型"],
      institution_id: this.filter["失活原因"]["分中心"],
    };
    if (this.filter["失活原因"]["统计时间"] === "自定义") {
      params.start_time = this.filter["失活原因"].startTime;
      params.end_time = this.filter["失活原因"].endTime;
    }
    PostshenhezheShihuo(this, params).then((res: any) => {
      this.data["失活原因"]["审核账户"] = res;
    });
  }

  private mounted() {
    this.getAllData();
    this.getFenzhongxinBingli();
    this.getXiaozuBingli();
    this.getYanjiuzheBingli();
    this.getFenzhongxinZhiliang();
    this.getXiaozuZhiliang();
    this.getYanjiuzheZhiliang();
    this.getFenzhongxinShihuo();
    this.getShenhezheShihuo();
  }
}
